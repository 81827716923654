<template>
    <card>
      <body-card style="margin: 0;border: none;">
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('dealer.application_form') }}</h4>
          </template>
          <template v-slot:body>
            <b-tabs content-class="mt-0" fill>
              <!--  Tab of Application Start  -->
              <b-tab :title="$t('license_management.application')" active>
                <div v-if="loading">
                  <Loading />
                </div>
                <b-overlay v-else>
                  <div class="p-3">
                    <b-row>
                      <b-col sm="12">
                        <div class="text-right">
                          <b-button variant="primary" @click="pdfExport" class="btn btn-sm mb-2">
                            <i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}
                          </b-button>
                        </div>
                        <div>
                          <div class="mb-4" style="display: flex; flex-wrap: wrap;">
                            <div class="" style="width:50%"><b>{{ $t('teaGardenService.application_id') }}</b> : {{
                              appDetail.application.app_id }}
                            </div>
                          </div>
                          <div class="stepper-wrapper">
                            <div :class="item.value <= appDetail.application.status ? `stepper-item completed` : `stepper-item`"
                              v-for="(item, index) in stepList" :key="index">
                              <div class="step-counter">
                                <i v-if="item.value <= appDetail.application.status" class="ri-check-fill"></i>
                                <span v-else>{{ $n(index+1) }}</span>
                              </div>
                              <div class="step-name">{{ item.text }}</div>
                            </div>
                          </div>
                          <!-- Application view start -->
                          <div class="application-form-wrapper application-form-view-wrapper mt-5">
                            <div>
                              <h5
                                style="border-bottom: 2px solid #2f3542;width: 80%;margin: 3rem auto;padding-bottom: 5px;text-align: center;">
                                {{ $t('teaGarden.ex_app_headline') }}
                              </h5>
                            </div>
                          </div>
                          <b-row>
                            <div md="12">
                              <h6 class="mb-3">{{ $t('teaGarden.sub_ex_app_detail') }}</h6>
                            </div>
                          </b-row>
                          <b-row>
                            <div md="12">
                              <h6 class="mb-3">{{ $t('teaGarden.a_ex_app_detail') }}</h6>
                            </div>
                          </b-row>
                          <b-row>
                            <b-col lg="12" sm="12">
                              <table class="table table-bordered" style="width:100%;">
                                <thead>
                                  <tr>
                                    <th>{{ $t('teaGardenConfig.tea_garden_name') }}</th>
                                    <th>{{ $t('teaGarden.r_2_prev_current_production') }}</th>
                                    <th>{{ $t('teaGarden.previous_year_actual_production_kg') }}</th>
                                    <th>{{ $t('teaGarden.current_year_production_kg') }}</th>
                                    <th>{{ $t('teaGarden.permission_for_tea_quantity_applied_kg') }}</th>
                                    <th>{{ $t('teaGarden.ex_app_auction_price') }}</th>
                                    <th>{{ $t('teaGarden.total_value_of_tea_quantity_applied_tk') }}</th>
                                    <th>{{ $t('teaGarden.ex_app_sees') }}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td class="text-center"> {{ getGardenName(appDetail.garden_id) }}</td>
                                    <td>{{ $n(appDetail.r_2_prev_current_production, { minimumFractionDigits: 2}) }}</td>
                                    <td>{{ $n(appDetail.production_previous_year, { minimumFractionDigits: 2}) }}</td>
                                    <td>{{ $n(appDetail.production_current_year, { minimumFractionDigits: 2 }) }}</td>
                                    <td>{{ $n(appDetail.application_qty, { minimumFractionDigits: 2 }) }}</td>
                                    <td>{{ $n(appDetail.avg_auction_price, { minimumFractionDigits: 2 }) }}</td>
                                    <td>{{ $n(appDetail.total_value, { minimumFractionDigits: 2 }) }}</td>
                                    <td>{{ $n(appDetail.total_payable_cess, { minimumFractionDigits: 2 }) }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </b-col>
                          </b-row>
                          <template v-if="appDetail.application.payment">
                          <b-row v-if="appDetail.application.payment.payment_type === 2">
                            <div md="12" class="mt-3">
                              <h6 class="mb-3">
                                {{ $t('teaGarden.kho_ex_app_detail') }}
                                <span v-if="appDetail.application.payment.bank_slip_serial_no">{{ $n(appDetail.application.payment.bank_slip_serial_no, { useGrouping: false })}}</span>
                                {{ $t('globalTrans.date') }} {{appDetail.application.payment.date|dateFormat}} {{$t('orgProfile.bank')+ ' ' + getBankList(appDetail.application.payment.bank_id) + ' ' + $t('teaGarden.go_ex_app_detail') + ' ' + $n(appDetail.application.payment.amount, { minimumFractionDigits: 2 }) }}</h6>
                            </div>
                          </b-row>
                          <b-row v-if="appDetail.application.payment.payment_type === 3">
                            <div md="12" class="mt-3">
                              <h6 class="mb-3">{{ $t('teaGardenService.kho_transaction_no') + ' ' +  appDetail.application.payment.transaction_number + ' ' +$t('teaGarden.go_ex_app_detail') + ' ' + $n(appDetail.application.payment.amount, { minimumFractionDigits: 2 }) }}</h6>
                            </div>
                          </b-row>
                        </template>
                          <b-row v-if="appDetail.application.status === 5 || appDetail.application.status === 6">
                            <div md="12" class="mt-3">
                              <h6 class="mb-3">{{ $t('teaGarden.ex_app_detail_1') + ' ' +  $n(appDetail.application_year, {useGrouping:false}) + ' ' + $t('teaGarden.ex_app_detail_2') + ' ' + getRound(appDetail.round) + ' ' + $n(appDetail.verified_qty) + ' ' + $t('teaGarden.ex_app_detail_4') }}</h6>
                            </div>
                          </b-row>
                          <b-row v-else>
                            <div md="12" class="mt-3">
                              <h6 class="mb-3">{{ $t('teaGarden.ex_app_detail_1') + ' ' +  $n(appDetail.application_year, {useGrouping:false}) + ' ' + $t('teaGarden.ex_app_detail_2') + ' ' + getRound(appDetail.round) + ' ' + $n(appDetail.application_qty) + ' ' + $t('teaGarden.ex_app_detail_3') }}</h6>
                            </div>
                          </b-row>
                          <b-row>
                            <b-col md="6">
                              <div>
                                <h6 class="mb-3 tag">{{ $t('dealer.important_document') }}</h6>
                              </div>
                              <div>
                                <ul>
                                  <li>
                                    <div class="d-flex justify-content-between">
                                      <span>{{ $n(1) }}. {{ $t('teaGarden.bank_noc_certificate') }}</span>
                                      <div class="list-btn">
                                        <div v-if="appDetail.bank_noc">
                                          <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                          <a target="_blank"
                                            :href="teaGardenServiceBaseUrl + (isImage(appDetail.bank_noc) ? 'storage/' : '') + appDetail.bank_noc"><i
                                              style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                        </div>
                                        <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="d-flex justify-content-between">
                                      <span>{{ $n(2) }}. {{ $t('teaGarden.vat_payment_receipt') }}</span>
                                      <div class="list-btn">
                                        <div v-if="appDetail.vat_payment">
                                          <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                          <a target="_blank"
                                            :href="teaGardenServiceBaseUrl + (isImage(appDetail.vat_payment) ? 'storage/' : '') + appDetail.vat_payment"><i
                                              style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                        </div>
                                        <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="d-flex justify-content-between">
                                      <span>{{ $n(3) }}. {{ $t('teaGarden.broker_document') }}</span>
                                      <div class="list-btn">
                                        <div v-if="appDetail.broker_document">
                                          <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                          <a target="_blank"
                                            :href="teaGardenServiceBaseUrl + (isImage(appDetail.broker_document) ? 'storage/' : '') + appDetail.broker_document"><i
                                              style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                        </div>
                                        <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="d-flex justify-content-between">
                                      <span>{{ $n(4) }}. {{ $t('teaGarden.bank_agriment') }}</span>
                                      <div class="list-btn">
                                        <div v-if="appDetail.bank_agriment">
                                          <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                          <a target="_blank"
                                            :href="teaGardenServiceBaseUrl + (isImage(appDetail.bank_agriment) ? 'storage/' : '') + appDetail.bank_agriment"><i
                                              style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                        </div>
                                        <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                      <b-col md="12" class="text-left">
                        <b-button @click="back" class="ml-3"><i class="ri-arrow-go-back-fill"></i>  {{ $t('teaGardenConfig.back') }}</b-button>
                      </b-col>
                    </b-row>
                  </div>
                </b-overlay>
              </b-tab>
              <!--  Tab of Application End  -->
              <!--  Tab of Forward Start  -->
              <b-tab :title="$t('license_management.forward_history')" v-if="$store.state.Auth.authUser.user_type === 1">
                <div v-if="forwarLoad || loading" class="col-md-12">
                  <forward-loading></forward-loading>
                </div>
                <div v-else class="row">
                  <div class="col-md-10 offset-md-1">
                    <div v-for="(forward, index) in appDetail.application.forwards" :key="index">
                      <b-card>
                        <b-card-title>
                          <h5 style="font-size:17px">{{ getCardTitle(forward.status) }}</h5>
                        </b-card-title>
                        <b-card-sub-title style="font-size:14px">
                          <b-row>
                            <b-col md="5">
                              <span class="badge badge-success">{{ $t('license_management.sender') }}</span> : {{
                              getUserName(forward.sender_id) }} <i class=" ml-3 ri-arrow-right-line"></i>
                            </b-col>
                            <b-col>
                              <p class="mb-2"><span class="badge badge-primary">{{ $t('license_management.receiver') }}</span> : {{
                                getUserName(forward.receiver_id) }}</p>
                              <p class="mb-2"><strong>{{ $t('globalTrans.designation') }} : </strong>
                                {{ getDesignationName(forward.designation_id) }}</p>
                              <p v-if="forward.sub_designation_id">
                                <strong>{{ $t('organogram.sub_designation') }} : </strong>
                                {{ getSubDesignationName(forward.sub_designation_id) }}</p>
                              <p><strong>{{ $t('globalTrans.office') }} : </strong>
                                {{ getOfficeName(forward.office_id) }}</p>
                            </b-col>
                          </b-row>
                        </b-card-sub-title>
                        <b-card-text class="mt-3">
                          <b>{{ $t('globalTrans.note') }}</b> : <span>{{ $i18n.locale === 'bn' ? forward.remarks_bn : forward.remarks
                            }}</span>
                        </b-card-text>
                      </b-card>
                    </div>
                  </div>
                </div>
              </b-tab>
              <!--  Tab of Forward End  -->
              <!--  Tab of Recommendation Start  -->
              <b-tab style="padding: 22px 15px 0;" v-if="$store.state.Auth.authUser.user_type === 1" :title="$t('teaGardenPanel.recommendation_history')">
                <div v-if="loading">
                  <RecommendationLoading />
                </div>
                <div v-else class="row">
                  <div class="col-sm-6" v-for="(recommendation, index) in appDetail.application.recommendations" :key="index">
                    <b-card v-if="recommendation.status !== 1" style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;border: none;">
                      <b-card-title style="padding-bottom: 5px;font-size:17px;font-weight: 600;border-bottom: 1px solid #ddd;">
                        {{ getCardTitleRecommendation(recommendation.status) }}
                      </b-card-title>
                      <b-card-text class="mt-3">
                        <p class="mb-2"><strong style="color: #77a0cb;">{{ $t('teaGardenPanel.by') }} : </strong> {{
                          getUserName(recommendation.user_id) }}</p>
                        <p class="mb-1"><strong>{{ $t('globalTrans.designation') }} : </strong>
                          {{ getDesignationName(recommendation.designation_id) }}</p>
                        <p class="mb-1"><strong>{{ $t('globalTrans.office') }} : </strong>
                          {{ getOfficeName(recommendation.office_id) }}</p>
                        <b>{{ $t('globalTrans.note') }}</b> : <span>{{ $i18n.locale === 'bn' ? recommendation.remarks :
                          recommendation.remarks
                          }}</span>
                      </b-card-text>
                      <div v-if="recommendation.attachment" class="text-right">
                        <a target="_blank"
                          :href="teaGardenServiceBaseUrl + (isImage(recommendation.attachment) ? 'storage/' : '') + recommendation.attachment"><button
                            style="padding: 2px 10px;" class="btn btn-success btn-sm"><i class="ri-download-2-line"></i> {{
                            $t('globalTrans.download') }}</button></a>
                      </div>
                    </b-card>
                  </div>
                </div>
              </b-tab>
              <!--  Tab of Recommendation End  -->
            </b-tabs>
          </template>
      </body-card>
    </card>
</template>
<script>
import RestApi, { authServiceBaseUrl, teaGardenServiceBaseUrl } from '@/config/api_config'
import RecommendationLoading from './../loading/RecommendationLoading.vue'
import ForwardLoading from './../loading/ForwardLoading.vue'
import Loading from './../loading/Details.vue'
// import { dateFormat } from '@/utils/fliter'
// import { tcbApplicationShow } from '../api/routes'
// import Pdf from './details-pdf'
export default {
  name: 'Details',
  components: {
    Loading,
    ForwardLoading,
    RecommendationLoading
  },
  data () {
    return {
      // loading: false,
      forwarLoad: false,
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      appDetail: {
        id: 0,
        applicant_name_en: '',
        applicant_name_bn: '',
        designation_en: '',
        designation_bn: '',
        tea_garden_name_en: '',
        tea_garden_name_bn: '',
        area_type_id: 0,
        division_id: 0,
        district_id: 0,
        city_corporation_id: 0,
        upazila_id: 0,
        pauroshoba_id: 0,
        union_id: 0,
        ward_id: 0,
        post_code: '',
        address_en: '',
        address_bn: '',
        have_other_tea_garden: 1,
        has_investing_capability: 1,
        income_tax_amount: '',
        bank_solvency_certificate: '',
        reason_of_rejection_en: '',
        reason_of_rejection_bn: '',
        trade_license_no: '',
        chief_executive_name_en: '',
        chief_executive_name_bn: '',
        mobile: '',
        email: '',
        others: [],
        proprietor_identity: {
          id: 0,
          garden_info_id: this.$route.query.id ? this.$route.query.id : 0,
          company_name_en: '',
          company_name_bn: '',
          proprietor_type: 1,
          experience: '',
          status: 2,
          owners: []
        },
        land_ownership_info: {
          id: 0,
          garden_info_id: this.$route.query.id ? this.$route.query.id : 0,
          ownership_acquired_type: 1,
          year_of_granting_lease: '',
          period_of_lease: '',
          total_grant_area: '',
          area_under_tea: '',
          total_suitable_land: '',
          total_unsuitable_land: '',
          favour_garden_land_en: '',
          favour_garden_land_bn: '',
          mouja_en: '',
          mouja_bn: '',
          khotian_no: '',
          dag_no: '',
          garden_sketch_map: '',
          land_schedule_copy: '',
          khotian_attested_copy: '',
          registered_deed_attested_copy: '',
          year_plantation: '',
          year_production: '',
          average_production: '',
          average_sale_price: '',
          appointed_broker_name_en: '',
          appointed_broker_name_bn: '',
          does_garden_possesses_factory: 1,
          production_capacity: '',
          transformer_capacity: '',
          withering_capacity: '',
          status: 2
        },
        other_info: {
          id: 0,
          garden_info_id: this.$route.query.id ? this.$route.query.id : 0,
          has_residence_facilities: 1,
          has_school_facilities: 1,
          has_hospital_facilities: 1,
          has_mosque_facilities: 1,
          has_club_facilities: 1,
          has_drinking_water_facilities: 1,
          no_of_officers: '',
          no_of_stuffs: '',
          no_of_workers: '',
          status: 2
        },
        witness_info: {
          id: 0,
          garden_info_id: this.$route.query.id ? this.$route.query.id : 0,
          first_witness_name_en: '',
          first_witness_name_bn: '',
          first_witness_address_en: '',
          first_witness_address_bn: '',
          second_witness_name_en: '',
          second_witness_name_bn: '',
          second_witness_address_en: '',
          second_witness_address_bn: '',
          are_agree: false,
          status: 2
        },
        application: {
          id: 0,
          issue_date: '',
          expired_date: '',
          status: 2,
          recommendations: [],
          forwards: []
        }
      },
      users: []
    }
  },
  created () {
    this.getAppDetail()
  },
  computed: {
    appId () {
      return this.$route.params.id
    },
    stepList () {
      // const tcbStepList = this.$store.state.LicenseRegistrationService.commonObj.tcbStepList.filter(item => item.value !== 5).map(item => {
      //   return this.$i18n.locale === 'bn' ? { value: item.value, text: item.text_bn } : { value: item.value, text: item.text_en }
      // })
      const tcbStepList = [
        { value: 2, text: this.$i18n.locale === 'en' ? 'Pending' : 'অপেক্ষমাণ', text_en: 'Pending', text_bn: 'অপেক্ষমাণ' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Forwarded' : 'ফরোয়ার্ড', text_en: 'Forwarded', text_bn: 'ফরোয়ার্ড' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Recommendation' : 'সুপারিশ', text_en: 'Recommendation', text_bn: 'সুপারিশ' },
        { value: 5, text: this.$i18n.locale === 'en' ? 'Recommended' : 'প্রস্তাবিত', text_en: 'Recommended', text_bn: 'প্রস্তাবিত' },
        { value: 6, text: this.$i18n.locale === 'en' ? 'Approved' : 'অনুমোদিত', text_en: 'Approved', text_bn: 'অনুমোদিত' },
        { value: 7, text: this.$i18n.locale === 'en' ? 'Rejected' : 'প্রত্যাখ্যাত', text_en: 'Rejected', text_bn: 'প্রত্যাখ্যাত' }
      ]
      if (this.appDetail.application.status === 7) {
        return tcbStepList.filter(item => item.value !== 6)
      } else {
        return tcbStepList.filter(item => item.value !== 7)
      }
    },
    currentLocale () {
      return this.$i18n.locale
    },
    nationalityList () {
      return [
        { text: this.currentLocale === 'en' ? 'Bangladeshi' : 'বাংলাদেশী', value: 1, text_en: 'Bangladeshi', text_bn: 'বাংলাদেশী' },
        { text: this.currentLocale === 'en' ? 'Others' : 'অন্যান্য', value: 2, text_en: 'Others', text_bn: 'অন্যান্য' }
      ]
    },
    yesNoList () {
      return [
        { text: this.currentLocale === 'en' ? 'Yes' : 'হ্যাঁ', value: 1, text_en: 'Yes', text_bn: 'হ্যাঁ' },
        { text: this.currentLocale === 'en' ? 'No' : 'না', value: 2, text_en: 'No', text_bn: 'না' }
      ]
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    forwardStatusList () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Pending' : 'অপেক্ষমাণ', text_en: 'Pending', text_bn: 'অপেক্ষমাণ' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Forwarded' : 'ফরোয়ার্ড', text_en: 'Forwarded', text_bn: 'ফরোয়ার্ড' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Approved' : 'অনুমোদিত', text_en: 'Approved', text_bn: 'অনুমোদিত' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Reject' : 'বাতিল', text_en: 'Reject', text_bn: 'বাতিল' }
      ]
      return list
    },
    recommendStatusList () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Pending' : 'অপেক্ষমাণ', text_en: 'Pending', text_bn: 'অপেক্ষমাণ' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Recommended' : 'প্রস্তাবিত', text_en: 'Recommended', text_bn: 'প্রস্তাবিত' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Forwarded' : 'ফরোয়ার্ড', text_en: 'Forwarded', text_bn: 'ফরোয়ার্ড' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Approved' : 'অনুমোদিত', text_en: 'Approved', text_bn: 'অনুমোদিত' },
        { value: 5, text: this.$i18n.locale === 'en' ? 'Reject' : 'বাতিল', text_en: 'Reject', text_bn: 'বাতিল' }
      ]
      return list
    }
  },
  methods: {
    getStatusName (status) {
      return status === 2 ? this.$t('dealer.reject') + ' ' + this.$t('globalTrans.note') : this.$t('dealer.resubmit') + ' ' + this.$t('globalTrans.note')
    },
    getBankList (id) {
      const bankData = this.$store.state.CommonService.commonObj.bankList.find(item => item.value === parseInt(id))
      if (typeof bankData !== 'undefined') {
        return this.$i18n.locale === 'bn' ? bankData.text_bn : bankData.text_en
      } else {
        return ''
      }
    },
    getMeritalStatus (id) {
      const maritalStatus = this.$store.state.LicenseRegistrationService.commonObj.maritalStatusList.find(item => item.value === parseInt(id))
      if (typeof maritalStatus !== 'undefined') {
        return this.$i18n.locale === 'bn' ? maritalStatus.text_bn : maritalStatus.text_en
      } else {
        return ''
      }
    },
    getCardTitle (status) {
      const tgServiceStep = this.forwardStatusList.find(item => item.value === parseInt(status))
      if (typeof tgServiceStep !== 'undefined') {
        return this.$i18n.locale === 'bn' ? tgServiceStep.text_bn : tgServiceStep.text_en
      } else {
        return ''
      }
    },
    getCardTitleRecommendation (status) {
      const tgServiceStep = this.recommendStatusList.find(item => item.value === parseInt(status))
      if (typeof tgServiceStep !== 'undefined') {
        return this.$i18n.locale === 'bn' ? tgServiceStep.text_bn : tgServiceStep.text_en
      } else {
        return ''
      }
    },
    back () {
      this.$router.go(-1)
    },
    async getAppDetail () {
        // this.loading = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        const result = await RestApi.getData(teaGardenServiceBaseUrl, '/btb/ex-garden-app/details/' + this.appId)
        if (result.success) {
          this.appDetail = result.data
          if (this.appDetail.application.forwards && this.appDetail.application.forwards.length) {
            await this.getForwardedUsers(this.appDetail.application.forwards)
          }
        }
        // this.loading = false
        this.$store.dispatch('mutateCommonProperties', { loading: false })
    },
    getAreaTypeName (id) {
      const obj = this.$store.state.commonObj.areaTypeList.find(item => item.value === parseInt(id))
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getGardenName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === parseInt(id))
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getCityCorName (id) {
      const data = this.$store.state.CommonService.commonObj.cityCorporationList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getPouroName (id) {
      const data = this.$store.state.CommonService.commonObj.municipalityList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getWardName (id) {
      const data = this.$store.state.CommonService.commonObj.wardList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getDivisionName (id) {
      const data = this.$store.state.CommonService.commonObj.divisionList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getUpazillaName (id) {
      const data = this.$store.state.CommonService.commonObj.upazilaList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getDistrictName (id) {
      const data = this.$store.state.CommonService.commonObj.districtList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getUnionName (id) {
      const data = this.$store.state.CommonService.commonObj.unionList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getOfficeName (id) {
      const data = this.$store.state.CommonService.commonObj.officeList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getRound (id) {
      const data = this.$store.state.TeaGardenService.commonObj.applicationPhaseList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getUserName (userId) {
      const user = this.users.find(user => parseInt(user.value) === parseInt(userId))
      return typeof user !== 'undefined' ? (this.$i18n.locale === 'bn' ? user.text_bn : user.text_en) : ''
    },
    async getForwardedUsers (forwards) {
      this.forwarLoad = true
      var userIds = []
      forwards.map((item, key) => {
        userIds.push(item.sender_id)
        userIds.push(item.receiver_id)
      })
      const params = Object.assign({}, { user_ids: userIds })
      await RestApi.getData(authServiceBaseUrl, '/user-detail-by-user-ids', params).then(response => {
          if (response.length) {
            this.users = response
          } else {
            this.users = []
          }
          this.forwarLoad = false
      })
    },
    getDealerType (id) {
      const type = this.$store.state.LicenseRegistrationService.commonObj.sellerTypeList.find(item => item.value === parseInt(id))
      if (typeof type !== 'undefined') {
        return this.$i18n.locale === 'bn' ? type.text_bn : type.text_en
      } else {
        return ''
      }
    },
    // pdfExport () {
    //     const reportTitle = this.$t('teaGardenPanel.small_tea_garden_registration')
    //     Pdf.exportPdfDetails(teaGardenServiceBaseUrl, '/configuration/report-heading/detail', 5, reportTitle, this)
    // },
    async pdfExport () {
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, app_id: this.appId })
      const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 18)
      if (service !== undefined) {
        if (service.office_type_id) {
          params.office_type_id = service.office_type_id
        }
        if (service.office_id) {
          params.office_id = service.office_id
        }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, '/btb/ex-garden-app/details/' + this.appId, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getNationalityName (id) {
      const obj = this.nationalityList.find(item => item.value === id)
      return obj?.text
    },
    getYesNoValue (id) {
      const obj = this.yesNoList.find(item => item.value === id)
      return obj?.text
    },
    isImage (path) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
    },
    getDesignationName (id) {
      const obj = this.$store.state.CommonService.commonObj.designationList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getSubDesignationName (id) {
      const obj = this.$store.state.CommonService.commonObj.subDesignationList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    }
  }
}
</script>
<style scoped>
  .tag {
    font-weight: 600;
    border-left: 4px solid #1c4261;
    padding-left: 6px;
  }
  .download-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 2px 4px;
  }
  .yes-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
  }
  .no-btn {
    color: red;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
    margin-right: 33px !important;
  }
  .tag {
    font-weight: 600;
    border-left: 4px solid #1c4261;
    padding-left: 6px;
  }
  .download-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 2px 4px;
  }
  .yes-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
  }
  .no-btn {
    color: red;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
    margin-right: 33px !important;
  }

.stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    }
    .stepper-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
    }

    @media (max-width: 768px) {
        font-size: 12px;
    }
    .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
    }

    .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
    }

    .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
    color: white;
    }

    .stepper-item.active {
    font-weight: bold;
    }

    .stepper-item.completed .step-counter {
    background-color: #4bb543;
    color:white;
    }

    .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #4bb543;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
    }

    .stepper-item:first-child::before {
    content: none;
    }
    .stepper-item:last-child::after {
    content: none;
    }
    .step-name {
        color: black;
        font-weight: bold;
        font-size: 11px;
    }
.tagTwo {
  position: relative;
  display: inline-block;
  border-radius: 6px;
  clip-path: polygon(20px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 20px);
  background: hsl(250deg, 100%, 40%);
  padding: 5px 25px;
  font-weight: 600;
  font-size: 12px;
  color: #FFF;
  transition: clip-path 500ms;
}
.tagTwo:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: hsl(252deg, 100%, 60%);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 6px 0;
  transition: transform 500ms;
}
</style>
